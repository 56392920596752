import React from 'react';
import { graphql } from 'gatsby';

import { Layout, Container, DangerouslySetInnerHtml } from 'layout';
import Video from 'components/Video';
import EpisodesSection from 'components/EpisodesSection';
import BodyRenderer from 'common/BodyRenderer';
import RelatedArticles from 'components/RelatedArticles';
import Breadcrumbs from 'components/Breadcrumbs';
import IntroSection from 'components/IntroSection';

import breakpoints from 'components/RelatedArticles/constants';
import './EpisodePage.scss';

const bodyStructure = {
  'Related episodes': (
    { properties: { relatedEpisodesTitle, relatedEpisodes, episodesCount } },
    keyId
  ) => (
    <EpisodesSection
      key={keyId}
      episodes={relatedEpisodes}
      relatedEpisodesTitle={relatedEpisodesTitle}
      episodesCount={episodesCount}
    />
  ),
  'Intro Section': ({ properties: { title, description } }, keyId) => (
    <IntroSection key={keyId} title={title} description={description} />
  ),
  'Related articles': (
    { properties: { relatedArticles, relatedArticlesReadMore, readAllLink } },
    keyId
  ) => {
    return (
      <Container key={keyId}>
        <RelatedArticles
          breakpoints={breakpoints}
          data={relatedArticles.nodes}
          readMoreText={relatedArticlesReadMore}
          articlesCount={4}
          ariaLabel={relatedArticlesReadMore}
          readAllLink={readAllLink}
        />
      </Container>
    );
  },
};

const EpisodePage = ({ data, uri }) => {
  const {
    page: {
      nodes: [
        {
          seoMetaTitle,
          seoCanonicalUrl,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          defaultCompositions,
          properties: { cmsVideo, hostedVideo, subtitle, description, body },
        },
      ],
    },
    relatedEpisodes,
    relatedArticles,
  } = data;

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaKeywords,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
    >
      <div className="episode-page">
        <div className="episode-page__breadcrumbs">
          <Breadcrumbs url={uri} />
        </div>

        <div className="episode-page__video-wrapper">
          <div className="episode-page__video">
            <Video videoURL={hostedVideo || cmsVideo?.fallbackUrl} />
          </div>
        </div>

        <div className="episode-page__text">
          <p className="episode-page__subtitle">{subtitle}</p>
          <DangerouslySetInnerHtml className="episode-page__description" html={description} />
        </div>

        <div className="episode-page__body">
          {body?.length > 0 ? (
            <BodyRenderer
              bodyData={body}
              bodyStructure={bodyStructure}
              bodyItemProps={{
                relatedEpisodes,
                relatedArticles,
              }}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query EpisodePage(
    $lang: String
    $url: String
    $relatedEpisodesLinks: [String]
    $relatedArticlesLinks: [String]
  ) {
    page: allUmbracoEpisodes(filter: { lang: { eq: $lang }, url: { eq: $url } }) {
      nodes {
        url
        seoMetaTitle
        seoCanonicalUrl
        seoMetaKeywords
        seoMetaDescription
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        defaultCompositions {
          ...DefaultCompositionsFragment
        }
        properties {
          ...EpisodeFragment
          body {
            structure
            properties {
              title
              description
              relatedEpisodesTitle
              relatedArticlesReadMore
              readAllLink {
                name
                url
              }
              episodesCount
            }
          }
        }
      }
    }
    relatedEpisodes: allUmbracoEpisodes(
      filter: { lang: { eq: $lang }, url: { in: $relatedEpisodesLinks } }
    ) {
      nodes {
        url
        properties {
          ...EpisodeFragment
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $relatedArticlesLinks } }) {
      nodes {
        id
        title
        properties {
          title
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default EpisodePage;
